/* Bentuk dengan clip-path untuk membuat sudut unik */
.service-card {
  clip-path: polygon(
    0% 15%,
    29% 15%,
    42% 0,
    57% 0,
    92% 0,
    93% 30%,
    93% 60%,
    100% 77%,
    100% 100%,
    0 100%,
    0 64%,
    0 33%
  );
  background-color: black;
}

