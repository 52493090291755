.creative-component {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.creative-component img {
  max-width: 100%;
  height: 100%; /* Set height to auto for responsive scaling */
  margin-right: 1rem;
}
