/* Responsive image container */
.image-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the image */
  position: relative;
  z-index: 0;
}

.image-container img {
  max-width: 100%;
  height: auto; /* Auto height for responsive scaling */
}

.content {
  position: relative;
  z-index: 10;
}

.icon {
  width: 30px; /* Default size for larger screens */
}

/* Media queries for responsive design */

/* For small screens (max-width: 600px) */
@media (max-width: 600px) {
  .image-container img {
    height: 25rem; /* Smaller height for mobile screens */
  }

  .icon {
    width: 20px; /* Smaller icon size */
  }

  .flex {
    padding: 0 10px; /* Reduce padding on smaller screens */
  }

  .text-base {
    font-size: 14px; /* Reduce font size on mobile */
  }
}

/* For medium screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .image-container img {
    height: 35rem; /* Adjust image height */
  }

  .icon {
    width: 25px; /* Slightly smaller icon */
  }

  .text-lg {
    font-size: 18px; /* Adjust text size */
  }
}
