@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Akira Expanded Demo';
  src: url('/public/assets/Font/Akira-Expanded-Demo.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  
}

@font-face {
  font-family: 'Arial Black';
  src: url('/public/assets/Font/ariblk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monsterrat Bold';
  src: url('/public/assets/Font/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Monsterrat SemiBold Italic';
  src: url('/public/assets/Font/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

html, body {
  margin: 0;
  font-family: 'Monsterrat Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-color: #2C2C2C; */
  background: linear-gradient(to bottom, #110616, #151518);
  /* background-color: #333333; */
  padding: 0;
}
.bg-gradient-to-r {
  background: linear-gradient(90deg, rgba(50,205,50,0.5), transparent);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
