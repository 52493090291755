.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important; /* Memastikan video mengisi lebar kontainer */
  height: 100% !important; /* Memastikan video mengisi tinggi kontainer */
}

.react-player video {
  object-fit: cover; /* Memastikan video menutupi seluruh layar tanpa distorsi */
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

/* Tambahkan media query untuk perangkat yang lebih kecil */
@media (max-width: 768px) {
  .react-player video {
    object-fit: cover;
    width: 100vw; /* Memastikan video tetap mengisi layar pada perangkat mobile */
    height: 100vh;
  }
}
